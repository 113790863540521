import React, { Component } from "react";
import moment from "moment";
import Modal from "react-modal";
import ShippingLabel from "../ShippingLabel/ShippingLabel";

import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";

import TableRow from "@material-ui/core/TableRow";
import config from "../../../config";
import errorFunctions from "../../../store/errorFunctions";
import "../../common.css";
import "./Table.css";

export default class TicketTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      device: null,
      loading: false,
    };
  }

  handleChangeStatus = (ticketId) => {
    this.setState({ isLoading: false });
    let payload = {
      status: "Pending Receipt of Goods",
    };
    this.props
      .protectedFetch(config.backendServer + `ticket/${ticketId}/status/`, "PATCH", payload, errorFunctions.checkResponseThenJson)
      .then(() => {
        this.setState({ loading: false });
        this.props.retrieveDeviceDetail(); //mini reload
      })
      .catch((err) => {
        console.log("fail", err);
        this.setState({ loading: false });
      });
  };

  handleReturnLabelClick = (ticket) => {
    if (ticket.status === "Pending Customer to Download Return Label") this.handleChangeStatus(ticket.id);
    this.setState({ showShipping: true, ticket: ticket });
  };

  render() {
    return (
      <div>
        {this.props.tickets.length > 0 && (
          <TableContainer>
            <TableHead>
              <TableRow>
                <TableCell>Ticket Number</TableCell>
                <TableCell>Warranty Status</TableCell>
                <TableCell>Created Date</TableCell>
                <TableCell>Last Update Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Tracking Number</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.tickets
                .sort((a, b) => (b.id - a.id ? -1 : 1))
                .map((ticket) => (
                  <TableRow key={ticket.id}>
                    <TableCell>{ticket.ticket_number}</TableCell>
                    <TableCell> {ticket.ticket_type.toUpperCase() === "OUT_OF_WARRANTY" ? " Out of Warranty" : " In Warranty"}</TableCell>
                    <TableCell>{moment(ticket.created).format("DD-MM-YYYY")}</TableCell>
                    <TableCell>{moment(ticket.last_activity_date).format("DD-MM-YYYY")}</TableCell>
                    <TableCell>{ticket.status}</TableCell>

                    <TableCell>
                      {ticket.tracking_number && ticket.tracking_number.includes("http") ? (
                        <a href={ticket.tracking_number} target={"blank"}>
                          Open Link
                        </a>
                      ) : (
                        ticket.tracking_number || ""
                      )}
                    </TableCell>
                    <TableCell>
                      {ticket?.return_label_url && (
                        <a href={ticket?.return_label_url || ""} target="_blank" rel="noopener noreferrer">
                          <button
                            className="k-btn k-btn-primary h-auto"
                            // onClick={() => {
                            //   this.handleReturnLabelClick(ticket);
                            // }}
                          >
                            Generate Your Return Label
                          </button>
                        </a>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </TableContainer>
        )}
        <Modal isOpen={this.state.showShipping_} contentLabel={"Export Report"} className={"fullpage-modal"}>
          <ShippingLabel
            protectedFetch={this.props.protectedFetch}
            userDevice={this.state.ticket?.user_device}
            // disableInput={!!this.props.details.transdirect_status}
            handleCloseModal={() => this.setState({ showShipping: false })}
            ticketId={this.state.ticket?.id}
            retrieveWarranty={() => this.props.retrieveWarranty(this.state.ticket?.id)}
          />
        </Modal>
        <Modal isOpen={this.state.showShipping} contentLabel={"Export Report"} className={"input-modal"}>
          <div className="card">
            <div className="card-header">View Return Label</div>
            <div className="card-body">
              {this.state.showShipping && <embed type="application/pdf" src={this.state.ticket.return_label} width="800" height="600"></embed>}
            </div>
            <div className="card-footer">
              <button
                className="k-btn k-btn-secondary"
                onClick={() => {
                  console.log(this.state.ticket);
                  this.setState({ showShipping: false });
                }}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
